import i18next from 'i18next';
import authRoles from '../auth/authRoles';
import en from './navigation-i18n/en';
import ru from './navigation-i18n/ru';
import uz from './navigation-i18n/uz';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('uz', 'navigation', uz);
i18next.addResourceBundle('ru', 'navigation', ru);

const navigationConfig = [
  {
    id: 'apps',
    title: 'Applications',
    type: 'group',
    auth: authRoles.user,
    icon: 'heroicons-outline:home',
    translate: 'Basic',
    children: [
      {
        id: 'apps.home',
        title: 'HomePage',
        type: 'item',
        icon: 'heroicons-outline:home',
        url: '/',
        translate: 'Main',
      },
      {
        id: 'myuzatom',
        title: 'MyUzAtom',
        type: 'collapse',
        icon: 'heroicons-outline:building-office-2',
        translate: 'MyUzAtom',
        auth: authRoles.user,
        children: [
          {
            id: 'apps.structure',
            title: 'Structure',
            type: 'item',
            icon: 'heroicons-outline:collection',
            url: '/apps/org-structure',
            translate: 'Structure',
          },
          {
            id: 'apps.map',
            title: 'Map',
            type: 'item',
            icon: 'heroicons-outline:map-pin',
            url: '/apps/map',
            translate: 'Map',
          },
        ],
      },
      {
        id: 'apps.chat',
        title: 'Chat',
        type: 'item',
        icon: 'heroicons-outline:chat-alt',
        url: '/apps/chat',
        translate: 'Chat',
      },
    ],
  },
  {
    id: 'work',
    title: 'Work',
    type: 'group',
    auth: authRoles.user,
    icon: 'heroicons-outline:home',
    translate: 'Work',
    children: [
      {
        id: 'apps.projects',
        title: 'Projects Management',
        type: 'item',
        icon: 'heroicons-outline:view-boards',
        url: '/apps/projects',
        translate: 'Projects',
        end: true,
      },
      {
        id: 'ElectronMail',
        title: 'ElectronMail',
        type: 'link',
        icon: 'heroicons-outline:envelope',
        url: 'https://mail.uzatom.uz/',
        translate: 'ElectronMail',
      },
      {
        id: 'EIjro',
        title: 'EIjro',
        type: 'link',
        icon: 'heroicons-outline:information-circle',
        url: 'https://edo.ijro.uz/welcome',
        translate: 'EDO.IJRO.UZ',
      },
      {
        id: 'hr',
        title: 'HR',
        type: 'collapse',
        icon: 'heroicons-outline:users',
        translate: 'HumanResources',
        auth: authRoles.hr,
        children: [
          {
            id: 'departments',
            title: 'Departments',
            icon: 'heroicons-outline:view-list',
            translate: 'Departments',
            type: 'item',
            url: 'hr/departments',
          },
          {
            id: 'users',
            title: 'Users',
            icon: 'heroicons-outline:user-group',
            type: 'item',
            url: 'hr/users',
            translate: 'Users',
          },
          {
            id: 'hrm-employees',
            title: 'Users',
            icon: 'heroicons-outline:user-group',
            type: 'item',
            url: 'hr/hrm-employees',
            translate: 'Employees',
          },
          {
            id: 'staff-table',
            title: 'Staffing table',
            icon: 'heroicons-outline:table',
            type: 'item',
            url: 'hr/staff-table',
            translate: 'StaffTable',
          },
        ],
      },
      {
        id: 'admin',
        title: 'Administration',
        type: 'collapse',
        auth: authRoles.admin,
        icon: 'heroicons-outline:cog',
        translate: 'Administration',
        children: [
          {
            id: 'apps.roles',
            title: 'Roles',
            type: 'item',
            icon: 'heroicons-outline:adjustments',
            url: 'admin/roles',
            translate: 'Roles',
            end: true,
          },
          {
            id: 'apps.users',
            title: 'Users',
            type: 'item',
            icon: 'heroicons-outline:user-group',
            url: 'admin/users',
            translate: 'Users',
            end: true,
          },
          {
            id: 'apps.references',
            title: 'References',
            type: 'item',
            icon: 'heroicons-outline:view-list',
            url: 'admin/references/Organizations',
            translate: 'References',
            end: true,
          },
          {
            id: 'apps.claimexecutors',
            title: 'ClaimExecutors',
            type: 'item',
            icon: 'heroicons-outline:flag',
            url: 'admin/claimexecutors',
            translate: 'ClaimExecutors',
            end: true,
          },
          {
            id: 'apps.crudMap',
            title: 'Maps',
            type: 'item',
            icon: 'heroicons-outline:map',
            url: 'admin/crud-map',
            translate: 'Maps',
            end: true,
          },
          {
            id: 'apps.logs',
            title: 'Logs',
            type: 'item',
            icon: 'heroicons-outline:document-text',
            url: 'admin/logs',
            translate: 'Logs',
            end: true,
          },
        ],
      },
      {
        id: 'apps.claims-processing',
        title: 'Projects Management',
        type: 'item',
        icon: 'heroicons-outline:clipboard',
        url: '/apps/claims-processing',
        translate: 'ClaimsProcessing',
      },
    ],
  },
  {
    id: 'useful',
    title: 'Useful',
    type: 'group',
    auth: authRoles.user,
    icon: 'heroicons-outline:home',
    translate: 'Useful',
    children: [
      {
        id: 'apps.profile',
        title: 'Profile',
        type: 'item',
        icon: 'heroicons-outline:user-circle',
        url: '/apps/profile',
        translate: 'Profile',
      },
      // {
      //   id: 'apps.skud',
      //   title: 'Skud',
      //   type: 'collapse',
      //   icon: 'heroicons-outline:users',
      //   translate: 'SkudEmployees',
      //   auth: authRoles.user,
      //   children: [
      //     {
      //       id: 'skud-departments',
      //       title: 'Employees',
      //       type: 'item',
      //       icon: 'heroicons-outline:clock',
      //       url: 'apps/skud/departments',
      //       translate: 'Skud',
      //     },
      //     {
      //       id: 'skud-report',
      //       title: 'Report',
      //       type: 'item',
      //       auth: authRoles.hr,
      //       icon: 'heroicons-outline:document-chart-bar',
      //       url: 'apps/skud/report',
      //       translate: 'SkudReport',
      //     },
      //   ],
      // },
      {
        id: 'documents',
        title: 'documents',
        type: 'collapse',
        icon: 'heroicons-outline:document-text',
        translate: 'Documents',
        auth: authRoles.user,
        children: [
          {
            id: 'apps.blank',
            title: 'Blanks',
            type: 'item',
            icon: 'heroicons-outline:document',
            url: '/apps/blanks',
            translate: 'Blanks',
          },
          {
            id: 'apps.presentation',
            title: 'Presentation',
            type: 'item',
            icon: 'heroicons-outline:presentation-chart-bar',
            url: '/apps/presentation',
            translate: 'Presentations',
          },
          {
            id: 'apps.internal-documents',
            title: 'InternalDocuments',
            type: 'item',
            icon: 'heroicons-outline:document-text',
            url: '/apps/internal-documents',
            translate: 'InternalDocuments',
          },
        ],
      },
      {
        id: 'apps.calendar',
        title: 'Calendar',
        type: 'collapse',
        icon: 'heroicons-outline:calendar',
        auth: authRoles.user,
        translate: 'Calendar',
        children: [
          {
            id: 'resources-calendar',
            title: 'Resources calendar',
            type: 'item',
            icon: 'heroicons-outline:calendar',
            url: 'apps/calendar/resources',
            translate: 'Reservation',
          },
          {
            id: 'my-calendar',
            title: 'My calendar',
            type: 'item',
            icon: 'heroicons-outline:calendar',
            url: 'apps/calendar/my',
            translate: 'MyCalendar',
          },
        ],
      },
      {
        id: 'apps.visitbooking',
        title: 'Appointment',
        type: 'item',
        icon: 'heroicons-outline:briefcase',
        url: '/apps/visit-booking',
        translate: 'VisitBooking',
      },
      {
        id: 'apps.helpcenter',
        title: 'HelpCenter',
        type: 'collapse',
        icon: 'heroicons-outline:question-mark-circle',
        translate: 'HelpCenter',
        auth: authRoles.user,
        children: [
          {
            id: 'helpdesk',
            title: 'HelpDesk',
            type: 'item',
            icon: 'heroicons-outline:phone',
            url: '/apps/helpdesk',
            translate: 'HelpDesk',
          },
          {
            id: 'support',
            title: 'Feedback',
            type: 'item',
            icon: 'heroicons-outline:support',
            url: '/apps/support',
            translate: 'Feedback',
          },
          // {
          //   id: 'download-app',
          //   title: 'Feedback',
          //   type: 'item',
          //   icon: 'heroicons-outline:archive-box-arrow-down',
          //   url: '/apps/download-app',
          //   translate: 'DownloadApp',
          // },
        ],
      },
    ],
  },
];

export default navigationConfig;
